var orginalFormAction = null;
function EndRequestHandlerAjaxTransfer() {
    theForm.action = orginalFormAction;
    theForm._initialAction = orginalFormAction;
}
function RegisterRequestHandlersAjaxTransfer() {
    if (typeof (Sys) != "undefined") {
        if (typeof (Sys.WebForms.PageRequestManager.getInstance()) != "undefined") {
            Sys.WebForms.PageRequestManager.getInstance().add_endRequest(EndRequestHandlerAjaxTransfer);
            Sys.Application.notifyScriptLoaded();
        }
    }
}
document.addEventListener("DOMContentLoaded", function () {
    if (typeof (Sys) != "undefined") {
        orginalFormAction = theForm.action;
        Sys.Application.add_load(RegisterRequestHandlersAjaxTransfer);
    }
})