//
//Trigger Validate
//
function RocktimeValidate(ValidatorName) {
    var validator = document.getElementById(ValidatorName);
    ValidatorValidate(validator);
}

function Page_ClientValidateByControl(controlId) {
    if (typeof (Page_InvalidControlToBeFocused) !== "undefined") { Page_InvalidControlToBeFocused = null; }
    if (typeof (Page_Validators) == "undefined") { return true; }
    var i;
    for (i = 0; i < Page_Validators.length; i++) {
        if (Page_Validators[i].controltovalidate == controlId) {
            ValidatorValidate(Page_Validators[i]);
            return;
        }
    }

}


//
//Custom Result Processing
//
function RocktimeCustomResultProcessing(val) {
    if (val.customResultProcessingJavascriptFunction == undefined | val.customResultProcessingJavascriptFunction == '') { return; }
    var value = ValidatorGetValue(val.controltovalidate);
    value = eval(val.customResultProcessingJavascriptFunction + '(value)');
    if (value != undefined) { document.getElementById(val.controltovalidate).value = value };
}

//
//Core
//
function RocktimeValidateEx(val) {
    val.isvalid = true;//GetCoreIsValid(val);
    return val.isvalid;//true;//prevents default behaviour for core validator
}

//
//DropDownList
//
function RocktimeDropDownListValidatorIsValid(val) {
    var value = ValidatorGetValue(val.controltovalidate);
    //if (val.allowCustomText == "true") { return (value != val.invalidValue); }
    //if (val.isLoadOnDemand == "true") { return (value != val.invalidValue); }
    //if (val.isDropDownList == "true") { return (value != val.invalidValue); }
    if (val.allowCustomText == "true") return RocktimeDropDownListValidatorIsInvalidValue(value, val);
    //if (val.isLoadOnDemand == "true") return RocktimeDropDownListValidatorIsInvalidValue(value, val);
    if (val.isDropDownList == "true") return RocktimeDropDownListValidatorIsInvalidValue(value, val);

    if (val.isRadDropDownList == "true") {
        var raddropdownlist = $find(val.controltovalidate);
        //return (raddropdownlist.get_selectedItem().get_value() != val.invalidValue);
        return RocktimeDropDownListValidatorIsInvalidValue(raddropdownlist.get_selectedItem().get_value(), val);
    }

    var combo = $find(val.controltovalidate);
    if (combo == null) {
        combo = $("#" + val.controltovalidate);
        value = combo.val();
        //return (value != val.invalidValue);
        return RocktimeDropDownListValidatorIsInvalidValue(value, val);
    }

    var text = combo.get_text();
    if (text.length < 1) { return false; }

    var node = combo.findItemByText(text);
    if (!node) {
        value = combo._value;
        //return (value != val.invalidValue);
        return RocktimeDropDownListValidatorIsInvalidValue(value, val);
    }

    //if (value == node.get_value()) { return true; }//assumes that the please select is not an item
    value = node.get_value();
    //return (value != val.invalidValue);
    return RocktimeDropDownListValidatorIsInvalidValue(value, val);
}

function RocktimeDropDownListValidatorIsInvalidValue(value, val) {
    return (value != val.invalidValue) && (value != val.invalidDefaultValue);;
}

//
//CheckBox
//
function RocktimeCheckBoxValidatorIsValid(val) {
    if (val.testType == 'CheckBox') {
        return $("#" + val.controltovalidate).is(':checked');
    }
    var checkBoxCount = 0;
    if (val.testType == "CheckBoxList") {
        $("#" + val.controltovalidate).find(":checkbox").each(function () {
            if ($(this).is(":checked")) { checkBoxCount++; }
        });
    } else if (val.testType == "RadListBox") {
        $("#" + val.controltovalidate).find(":checkbox").each(function () {
            if ($(this).is(":checked")) { checkBoxCount++; }
        });
    } else {
        var value = ValidatorGetValue(val.controltovalidate);
        if (ValidatorTrim(value).length == 0) { checkBoxCount = 0; }
        else { checkBoxCount = value.split(",").length; }
    }

    if (val.requiredChecked == 'false' && checkBoxCount == 0) { return true; }
    if (val.minimumChecked == '' && val.maximumChecked == '') { return true; }
    if (val.minimumChecked == '' && checkBoxCount <= val.maximumChecked) { return true; }
    if (val.maximumChecked == '' && checkBoxCount >= val.minimumChecked) { return true; }
    return (checkBoxCount <= val.maximumChecked && checkBoxCount >= val.minimumChecked);
}

//
//ListBox
//
function RocktimeListBoxValidatorIsValid(val) {
    var checkBoxCount = 0;

    if ($("#" + val.controltovalidate + "_ClientState").length == 0) {
        $("#" + val.controltovalidate).find("option").each(function () {
            if ($(this).is(":selected")) { checkBoxCount++; }
        });
    }
    else {
        checkBoxCount = $("#" + val.controltovalidate + " .rlbSelected").length
    }
    if (val.minimumSelected == '' && val.maximumSelected == '') { return true; }
    if (val.minimumSelected == '' && checkBoxCount <= val.maximumSelected) { return true; }
    if (val.maximumSelected == '' && checkBoxCount >= val.minimumSelected) { return true; }
    return (checkBoxCount <= val.maximumSelected && checkBoxCount >= val.minimumSelected);
}

//
//RadioButton
//
function RocktimeRadioButtonRequiredValidatorIsValid(val) {
    var checkedClassName = val.checkedClassName;
    var controlToValidate = $("#" + val.controltovalidate);
    var checkedOptions = controlToValidate.find("." + checkedClassName);
    return (checkedOptions.length == 1);
}

//
//TextBox Length
//
function RocktimeTextBoxLengthValidatorIsValid(val) {
    var value = ValidatorGetValue(val.controltovalidate);
    if (ValidatorTrim(value).length == 0) { return true; }
    if (val.minimumlength == '' && val.maximumlength == '') { return true; }
    if (val.minimumlength == '' && value.length <= val.maximumlength) { return true; }
    if (val.maximumlength == '' && value.length >= val.minimumlength) { return true; }
    return (value.length <= val.maximumlength && value.length >= val.minimumlength);
}

//
//TextBox Required
//
function RocktimeTextBoxRequiredValidatorIsValid(val) {
    if (val.IsFileUploadEx == "true") {
        if (val.FileExists == "true") { return true; }
    }
    var value;
    if (val.IsWYSIWYG == "true") {
        value = $find(val.controltovalidate).get_html();
        value = value.replace(/[ \n\r\t]|<[^>]+>|&nbsp;/gmi, '');
        value = ValidatorTrim(value);
        return (value.length != 0) && (value != ValidatorTrim(val.initialValue));
    }
    value = ValidatorGetValue(val.controltovalidate);
    return (ValidatorTrim(value).length != 0) && (ValidatorTrim(value) != ValidatorTrim(val.initialValue))
}

//
//TextBox Password Strength
//
function RocktimePasswordStrengthValidatorIsValid(val) {
    var value = ValidatorGetValue(val.controltovalidate);
    return pass_chkPass(value, val.id);
}

//
//TextBox Numeric
//
function RocktimeNumericValidatorIsValid(val) {
    return RocktimeNumericValidatorAction(val.controltovalidate, (val.allowdecimal == "true"), val.fieldErrorClass);
}

function RocktimeNumericValidatorAction(id, allowDecimal, fieldErrorClass) {
    var result;
    var val = $("#" + id).val();
    val = val.replace(/,/g, "");
    if (val == "") {
        result = true;
    } else {
        if (allowDecimal) { result = $.isNumeric(val); }
        else { result = isInt(val); }
    }

    if (result) {
        $('#' + id).removeClass(fieldErrorClass);
    } else {
        $('#' + id).addClass(fieldErrorClass);
    }
    return result;
}

function isInt(n) {
    return parseFloat(n) == parseInt(n, 10) && !isNaN(n);
}

//
//TextBox Time
//
function RocktimeTimeValidatorIsValid(val) {
    var value = ValidatorGetValue(val.controltovalidate);
    if (value == "") { return true; }
    if (!RocktimeTimeValidatorValidateTime(value)) { return false; }
    if (val.minimumtime == '' && val.maximumtime == '') { return true; }
    if (val.minimumtime == '' && RocktimeTimeValidatorGetTime(value) <= RocktimeTimeValidatorGetTime(val.maximumtime)) { return true; }
    if (val.maximumtime == '' && RocktimeTimeValidatorGetTime(value) >= RocktimeTimeValidatorGetTime(val.minimumtime)) { return true; }
    return (RocktimeTimeValidatorGetTime(value) <= RocktimeTimeValidatorGetTime(val.maximumtime)) && RocktimeTimeValidatorGetTime(value) >= RocktimeTimeValidatorGetTime(val.minimumtime);
}

function RocktimeTimeValidatorGetTime(Time) {
    var Parts;

    if (Time.indexOf("-") != -1) {
        Parts = Time.split("-");
        return new Date(2000, 1, 1, Parts[3], Parts[4], Parts[5]);
    }

    Parts = Time.split(":");

    if (Parts.length == 2) { return new Date(2000, 1, 1, Parts[0], Parts[1], 0); }
    if (Parts[2].indexOf(".") != -1) { return new Date(2000, 1, 1, Parts[0], Parts[1], Parts[2]); }

    var Seconds = Parts[2].split(".");
    return new Date(2000, 1, 1, Parts[0], Parts[1], Seconds[0], Seconds[1]);

}

function RocktimeTimeValidatorValidateTime(Time) {

    var Parts;
    if (Time.indexOf("-") != -1) {
        Parts = Time.split("-");
        return (Parts.length == 6);

    }

    Parts = Time.split(":")
    if (Parts.length == 2) {
        if (Parts[0] < 0) { return false };
        if (Parts[0] > 23) { return false };
        if (Parts[1] < 0) { return false };
        if (Parts[1] > 59) { return false };
        return true;
    }
    if (Parts.length == 3) {
        if (Parts[0] < 0) { return false };
        if (Parts[0] > 23) { return false };
        if (Parts[1] < 0) { return false };
        if (Parts[1] > 59) { return false };
        if (Parts[2] < 0) { return false };
        if (Parts[2] >= 60) { return false };
        return true;
    }
    return false;

}

function RocktimeTimeValidateControl(controlToValidateId) {
    var validator = document.getElementById(controlToValidateId);
    RocktimeValidateEx(validator);
}

//
//TextBox Regular Expression
//
function RocktimeRegularExpressionValidatorEvaluateIsValid(val) {
    var value = ValidatorGetValue(val.controltovalidate);
    switch (val.setCase) {
        case "u": value = value.toUpperCase(); break;
        case "l": value = value.toLowerCase(); break;
    }
    if (val.removeChars != '') {
        var pattern = new RegExp('[' + val.removeChars + ']', 'gmi');
        value = value.replace(pattern, '');
    }
    if (ValidatorTrim(value).length == 0)
        return true;
    var rx = new RegExp(val.validationexpression);
    var matches = rx.exec(value);
    return (matches != null && value == matches[0]);
}

//
//TextBox Compare
//
function CompareValidatorEvaluateIsValidEx(val) {
    var result = CompareValidatorEvaluateIsValid(val)
    return result;

}

//
//TextBox Compare Ajax
//
function RocktimeAjaxValidatorEvaluateIsValid(val) {
    return (val.ajaxIsValid.toLowerCase() == 'true');
}

function RocktimeAjaxValidatorKeyUp(val, controlToValidateId) {
    val = document.getElementById(controlToValidateId);//Reconect val to validator span after ajax call
    if (val.timeoutId) { window.clearTimeout(val.timeoutId); }
    val.timeoutId = window.setTimeout(function () {
        var value = ValidatorGetValue(val.controltovalidate);
        eval(val.webFormDoCallback);
    }, 200);
}

// Called when result is returned from server
function RocktimeAjaxValidatorResult(returnValue, val) {
    val.update = !(val.LastIsValid == returnValue) ? 'true' : 'false';
    val.ajaxIsValid = returnValue;
    val.LastIsValid = returnValue;
    val.isvalid = (val.ajaxIsValid.toLowerCase() == 'true');
    if (val.update == 'true') {
        ValidatorUpdateDisplay(val);
        if (val.parent != undefined && val.parent != '') {
            val.parentObj = document.all ? document.all[val.parent] : document.getElementById(val.parent)
            RocktimeValidateEx(val.parentObj);
        };
    }
}

// If there is an error, show it
function RocktimeAjaxValidatorError(message, val) {
    alert('Error: ' + decodeHTML(message));
    val.ajaxIsValid = 'false';
}

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};
