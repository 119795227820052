var popoutMessagePanel = {};

popoutMessagePanel.bind = function () {
    popoutMessagePanel.ctrl.addEventListener('click', function () { popoutMessagePanel.toggleView(); });
    popoutMessagePanel.viewClose.addEventListener('click', function () { popoutMessagePanel.toggleView(); });
    popoutMessagePanel.view.addEventListener('click', popoutMessagePanel.clearLoadTime);
};

popoutMessagePanel.clearLoadTime = function () {
    clearTimeout(popoutMessagePanel.loadTimer);
    popoutMessagePanel.view.removeEventListener('click', popoutMessagePanel.clearLoadTime);
};

popoutMessagePanel.toggleView = function () {
    if (popoutMessagePanel.mainDiv.classList.contains(popoutMessagePanel.mainDivHiddenClass)) {
        popoutMessagePanel.openView();
        return;
    }
    popoutMessagePanel.closeView();

};

popoutMessagePanel.closeView = function () {
    popoutMessagePanel.mainDiv.classList.add(popoutMessagePanel.mainDivHiddenClass);
    //    popoutMessagePanel.view.style.maxHeight = 0 + "px";
    popoutMessagePanel.view.style.height = 0 + "px";
};

popoutMessagePanel.openView = function () {
    popoutMessagePanel.mainDiv.classList.remove(popoutMessagePanel.mainDivHiddenClass);
    popoutMessagePanel.view.style.height = window.innerHeight + "px";
    //    popoutMessagePanel.view.style.maxHeight = window.innerHeight + "px";
};

popoutMessagePanel.load = function () {
    popoutMessagePanel.loadTimer = setTimeout(function () {
        popoutMessagePanel.closeView();
        popoutMessagePanel.view.removeEventListener('click', popoutMessagePanel.clearLoadTime);
    }, popoutMessagePanel.loadDisplayTime);
};

popoutMessagePanel.init = function () {
    popoutMessagePanel.mainDiv = document.getElementsByClassName('c-popout-message-panel')[0];
    popoutMessagePanel.view = document.getElementsByClassName('c-popout-message-panel__view')[0];
    if (!popoutMessagePanel.view) return;
    popoutMessagePanel.ctrl = document.getElementsByClassName('c-popout-message-panel__ctrl-button')[0];
    popoutMessagePanel.viewClose = document.getElementsByClassName('c-popout-message-panel__view-close')[0];
    popoutMessagePanel.mainDivHiddenClass = 'c-popout-message-panel--hidden';
    popoutMessagePanel.loadDisplayTime = 500;
    popoutMessagePanel.openView();
    popoutMessagePanel.bind();
    popoutMessagePanel.load();

};

document.addEventListener("DOMContentLoaded", function () {
    popoutMessagePanel.init();
});