//Setup Help
function setupHelp() {
    $('.rtHelpButton').attr('href', 'javascript:void(0);');
    $('.rtHelpButton').attr('target', '');
    $('.rtHelpMessage').click(function () { $(this).hide(); });
}


//Help
function rtHelpOpen(HelpMessage, LinkId) {
    $('#' + HelpMessage).text('Loading....');
    $('#' + HelpMessage).show();
    $('#' + HelpMessage).load('/help/summary/' + LinkId);
}
//Help
function rtHelpOpenMessage(HelpMessage, Text) {
    $('#' + HelpMessage).html(Text);
    $('#' + HelpMessage).show();
}