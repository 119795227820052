//TextArea
function initTextAreas() {
    $(".rtTACView").each(function () {
        $(this).text($(this).parent().parent().find("TextArea").text().replace(/\n/gi, "<br />").replace(/\r/, ""));
        $(this).html($(this).html().replace(/&lt;br \/&gt;/gi, "<br />"));
    });
    $(".rtTACEdit").hide();
}

function moveCaretToEnd(el) {
    moveCaretTo(el, el.val().length, el.val().length)
}

function moveCaretTo(el, selectionStart, selectionEnd) {
    el.focus();
    var input = el[0];
    if (input.setSelectionRange) {
        selectionStart = adjustOffset(input, selectionStart);
        selectionEnd = adjustOffset(input, selectionEnd);
        input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
        var range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else if (input.selection) {
        var range = input.createRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else {
        el.val(el.val());
    }
};

function adjustOffset(el, offset) {
    var val = el.value, newOffset = offset;
    if (val.indexOf("\r\n") > -1) {
        var matches = val.replace(/\r\n/g, "\n").slice(0, offset).match(/\n/g);
        newOffset += matches ? matches.length : 0;
    }
    return newOffset;
}