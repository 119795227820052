//
//Intercept and extend ValidationSummaryOnSubmit
//
var ValidationSummaryOnSubmitSaved;
document.addEventListener("DOMContentLoaded", function () {
    if (typeof (ValidationSummaryOnSubmit) == "function") {
        ValidationSummaryOnSubmitSaved = ValidationSummaryOnSubmit;
        ValidationSummaryOnSubmit = ValidationSummaryOnSubmitEx;
    }
}
)

function ValidationSummaryOnSubmitEx(validationGroup) {
    ValidationSummaryOnSubmitSaved(validationGroup);
    UpdateSummaryWrappers(validationGroup);
}

//
//Summary Update
//
function UpdateSummaryWrappers() {
    if (typeof (Page_ValidationSummaries) == "undefined") return;
    for (var sums = 0; sums < Page_ValidationSummaries.length; sums++) {
        var summary = Page_ValidationSummaries[sums];
        if (!summary) continue;
        var summaryTarget = GetSummaryTarget(summary.validationGroup);
        if (SummaryHasEMShowing(summaryTarget) || SummaryIsVisible(summaryTarget)) $(summaryTarget).show();
        else $(summaryTarget).hide();
    }
}

function SummaryIsVisible(summaryTarget) {
    if ($(summaryTarget + ' .error-componant__validator').css('display') == 'none') return false;
    return true;
}

function SummaryHasEMShowing(summaryTarget) {
    return ($(summaryTarget).attr('data-state-has-em') == 'true');
}

function GetSummaryTarget(validationGroup) {
    var summaryTarget = validationGroup;
    if (!summaryTarget || summaryTarget == '') summaryTarget = 'no-group';
    summaryTarget = '.error-componant-' + summaryTarget;
    return summaryTarget;
}

