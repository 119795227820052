// For xml date+time;
//Url
function addTrailingDirSlash(url) {
    if (url == "") { return "" }
    url = url.replace(/\/\/+/gi, "/").toLowerCase();
    if (url.charAt(0) == '/') { url = url.substring(1) }
    if (url == "" || url == "/") { return "" }
    var urlParts = url.split("/");
    if (urlParts[urlParts.length - 1].indexOf(".") !== -1) { return url }
    return (url + "/").replace(/\/\/+/gi, "/");
}



// Disable button
function disableButtonOnClick(target, text, waitText, seconds) {
    Page_ClientValidate();
    if (!Page_IsValid) return false;
    return disableButton(target, text, waitText, seconds);
}

function disableButton(target, text, waitText, seconds) {
    if (target.countdownInterval != undefined) return false
    target.countdownValue = seconds;
    target.text = text;
    target.waitText = waitText;
    disableButtonCheck(target);
    target.countdownInterval = setInterval(() => { disableButtonCheck(target); }, 1000);
    return true;
}

function disableButtonCheck(target) {
    if (target.countdownValue <= 0) {
        target.disabled = false;
        target.value = target.text;
        target.classList.remove("aspNetDisabled");
        clearInterval(target.countdownInterval);
        target.countdownInterval = null;
    }
    else {
        //target.disabled = true;
        target.value = target.text + " " + target.waitText + " " + target.countdownValue;
        target.classList.add("aspNetDisabled");
    }
    target.countdownValue -= 1;
}

function setPopupParentHTML(src) {
    src = document.getElementById(src);
    var dest = top.document.getElementById('ParentPopupTarget');
    dest.innerHTML = src.innerHTML;
}




document.addEventListener('DOMContentLoaded', () => {
    var elements = document.getElementsByClassName("click-to-copy-me")
    Array.from(elements).forEach(function (element) {
        element.addEventListener('click', (e) => {
            //debugger;
            var copyText = e.currentTarget.querySelector('.click-to-copy-me-text');
            var textArea = document.createElement("textarea");
            var textContent = copyText.textContent
            textArea.value = textContent;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();

            var message = `'${textContent}'\nhas been copied to your clipboard`;
            if (!("Notification" in window)) {
            }
            else if (Notification.permission === "granted") {
                const notification = new Notification(message);
            }
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        const notification = new Notification(message);
                    }
                });

            }
        });
    })
})



