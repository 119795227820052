Date.prototype.toXML = function () {
    return (this.toXMLDate() + "T" + this.toXMLTime());
}

// For xml date;
Date.prototype.toXMLDate = function () {
    return this.getFullYear() + "-" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "-" + ((this.getDate() < 10) ? "0" : "") + this.getDate();
}

// For xml time;
Date.prototype.toXMLTime = function () {
    return this.timeNow();
}

// For todays date;
Date.prototype.today = function () {
    return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "/" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "/" + this.getFullYear();
}

// For the time now
Date.prototype.timeNow = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes();
}

// For the time now
Date.prototype.timeNowSeconds = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
}

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

String.prototype.endsWith = function (str) {
    return (this.match(str + "$") == str)
}

String.prototype.startsWith = function (str) {
    return (this.match("^" + str) == str)
}

String.prototype.formatEx = function (tokens) {
    var str = this;
    if (!tokens) return str;
    if (!Array.isArray(tokens)) return str.replace(new RegExp('\\{0\\}', 'g'), tokens);
    for (var i = 0; i < tokens.length; i++) {
        str = str.replace(new RegExp('\\{' + i + '\\}', 'g'), tokens[i]);
    }
    return str;
}

String.prototype.trim = function () {
    return (this.replace(/^[\s\xA0]+/, "").replace(/[\s\xA0]+$/, ""))
}

String.prototype.replaceAll = function (search, replacement) {
    return this.split(search).join(replacement);
};

Object.defineProperties(Date, {
    minValue: { value: -8640000000000000 }, // A number, not a date
    maxValue: { value: 8640000000000000 }
});

Element.prototype.getInfo = function () {
    const rect = this.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        centerX: rect.left + window.scrollX + ((rect.right - rect.left) / 2),
        centerY: rect.top + window.scrollY + ((rect.bottom - rect.top) / 2),
        x: rect.x,
        y: rect.y
    };
}

HTMLElement.prototype.addEventListenerEx = function (type, func, capture) {
    var target = this;
    var newTarget = target.cloneNode(true);
    target.parentNode.replaceChild(target, newTarget);
    newTarget.addEventListener(type, func, capture);
}

HTMLElement.prototype.addClick = function (func, capture) {
    this.addEventListenerEx('click', func, capture);
}

function addEventListenerById(id, type, func, capture) {
    document.getElementById(id).addEventListenerEx(type, func, capture);
}

function addEventListenerByClass(className, type, func, capture) {
    const targets = document.querySelectorAll(className);
    targets.forEach(target => target.addEventListenerEX(type, func, capture));
}

function addClickById(id, func, capture) {
    addEventListenerById(id, 'click', func, capture);
}

function addClickByClass(className, func, capture) {
    addEventListenerByClass(className, 'click', func, capture);
}

