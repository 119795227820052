/*
    cookie policy
    Version:2
    Dependencies: jQuery
  
    SUMMARY:

    Controls cookie policy display setting and removing cookies. Currently built to work with RocktimeCore.config setting
    EnableNewStyleCookiePolicyVersionNo = 3. This is the default so no settings need to be set in the initialise example below.

*/

document.addEventListener("DOMContentLoaded", function () {

    if (document.getElementById("CookiePolicyAcceptAll") !== null) {
        document.getElementById("CookiePolicyAcceptAll").onclick = function () {
            var date = new Date();
            date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));
            document.cookie = "rtCookiePrivacySetting=AcceptedTypes=ALL; expires=" + date.toUTCString() + "; SameSite=Strict; path=/;";

            document.getElementById("CookiePolicy").style.display = 'none';
            if (document.getElementById("CookiePolicyModal") !== null) { document.getElementById("CookiePolicyModal").style.display = 'none'; }
        };
    }

    if (document.getElementById("CookieControlCentre") !== null) {
        document.getElementById("CookieControlCentre").onclick = function () {
            document.getElementById("CookiePolicy").style.display = 'none';
            if (document.getElementById("CookiePolicyModal") !== null) { document.getElementById("CookiePolicyModal").style.display = 'none'; }
        };
    }
});
