// Custom browser upload feature that hides features upon button clicks, and changes the browser default input file type to use a custom build input workaround
var uploader = {};

uploader.bind = function () {
    uploader.initFakeInput();

    uploader.cancelBtn.off("click").on("click", function () {

        uploader.removeFile($(this));
        uploader.hideFileInput($(this));
        uploader.showFileDetails($(this));
    });

    uploader.replaceBtn.off("click").on("click", function () {

        uploader.hideFileDetails($(this));
        uploader.showFileInput($(this));
    });
};

uploader.initFakeInput = function () {
    for (var i = 0; i < uploader.container.length; i++) {
        var container = $(uploader.container[i]);
        var imageUpload = container.find(uploader.inputTarget);

        imageUpload.on("change", function () {

            var uploadMsg = $(this).parents("." + uploader.containerClass).find("." + uploader.fileUploadMsgClass),
                input = this.files[0],
                text,
                val = $(this).val();

            if (input) {
                text = 'Selected File to upload: <strong>' + val.replace(/.*\\(.+)/g, "$1") + "</strong>";
            } else {
                text = 'Please select a file';
            }

            uploadMsg.html(text);
        });
    }
};

uploader.removeFile = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var input = container.find(uploader.inputTarget);
    var uploadMsg = container.find("." + uploader.fileUploadMsgClass);

    input.val("");
    uploadMsg.html("No File Selected");
};

uploader.hideFileInput = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileOpts = container.find("." + uploader.fileOptsClass);
    fileOpts.addClass(uploader.fileOptsHiddenClass);
};

uploader.showFileInput = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileOpts = container.find("." + uploader.fileOptsClass);
    fileOpts.removeClass(uploader.fileOptsHiddenClass);
    var input = container.find(uploader.inputTarget);
    input.focus();
};

uploader.hideFileDetails = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileDetails = container.find("." + uploader.fileDetailsClass);
    fileDetails.addClass(uploader.fileDetailsHiddenClass);
};

uploader.showFileDetails = function (selected) {
    var container = selected.parents("." + uploader.containerClass);
    var fileDetails = container.find("." + uploader.fileDetailsClass);
    fileDetails.removeClass(uploader.fileDetailsHiddenClass);
    fileDetails.find("." + uploader.replaceBtnClass).focus();
};

uploader.init = function () {
    if (typeof jQuery == 'undefined') return;
    uploader.containerClass = "c-file-upload";
    uploader.cancelBtnClass = "c-file-upload__cancel";
    uploader.replaceBtnClass = "c-file-upload__replace";
    uploader.fileOptsClass = "c-file-upload__file-opts";
    uploader.fileOptsHiddenClass = "c-file-upload__file-opts--hidden";
    uploader.fileDetailsClass = "c-file-upload__file-details";
    uploader.fileDetailsHiddenClass = "c-file-upload__file-details--hidden";
    uploader.fileUploadMsgClass = "c-file-upload__upload-msg";
    uploader.inputTarget = "input[type=file]";

    uploader.container = $("." + uploader.containerClass);
    uploader.fileInput = uploader.container.find(uploader.inputTarget);

    if (uploader.fileInput.length > 0) {

        uploader.cancelBtn = uploader.container.find("." + uploader.cancelBtnClass);
        uploader.replaceBtn = uploader.container.find("." + uploader.replaceBtnClass);

        uploader.bind();
    }
};

document.addEventListener("DOMContentLoaded", function () {
    uploader.init();
});
