var cbox = {};

cbox.onComplete = function (id, width, height, timerInMSecs) {

    var minWidth = 250,
        minHeight = 75,
        maxHeight = 450,
        x = 0,
        y=0;
    if (width == 'auto') x = $('#' + id).width() + 20; else x = width;
    if (timerInMSecs === 0) x += 60;
    if (x < minWidth) x = minWidth;

    if (width == 'auto') y = $('#' + id).height() + 15; else y = height;
    if (y < minHeight) y = minHeight;
    if (y > maxHeight) y = maxHeight;

    $.colorbox.resize({ width: x, height: y })

}