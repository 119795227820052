//RadCombobox LoadOnDemand

function ComboboxListLocked_OnClientRequestFailedHandler(sender, eventArgs) {
    eventArgs.set_cancel(true);
    var text = sender.get_text();
    if (text.length == 0) return

    text = text.substring(0, text.length - 1)
    sender.set_text(text);
    sender.searchText = text;
    sender.isFailedSearch = true;
    sender.requestItemsIsRunning = true;
    sender.requestItems();
}

function ComboboxListLocked_OnClientItemsRequesting(sender, eventArgs) {
    sender.requestItemsIsRunning = true;
    if (!sender.isFailedSearch) return
    var context = eventArgs.get_context();
    context["searchText"] = sender.searchText;
    sender.set_text(sender.searchText);
    sender.searchText = "";
    sender.isFailedSearch = false;
}

function Combobox_OnClientItemsRequested(sender, eventArgs) {
    sender.requestItemsIsRunning = false;
}

function Combobox_OnClientLoad(sender) {
    sender.requestItemsIsRunning = false;
}


