if (!SIDAction) var SIDAction = {};

//Click+ (Result Types)
//Ctrl        : Short Default Action(Input -> .Input.TextBox("sid",) or Get -> .Find("sid")
//Alt         : Short Get -> .Find("sid")
//Shift       : SID only -> "sid"
//Ctrl + Shift: Long Default Action(Input -> Driver.Input.TextBox("sid",) or Get -> Driver.Find("sid")
//Alt + Shift : Long Get -> Driver.Get("sid")

SIDAction.getKeyCPC = function (codeType, SID) {

    switch (codeType) {
        case 'cpc-free-text-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-number-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-date-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-time-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-boolean-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-string-readonly': SIDAction.processKey(['.Get.CPC("{0}").Get_ReadOnly\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-free-text': SIDAction.processKey(['.Get.CPC("{0}").Input_ByFreeText("")\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-number': SIDAction.processKey(['.Get.CPC("{0}").Input()\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-date': SIDAction.processKey(['.Get.CPC("{0}").Input(New Date())\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-time': SIDAction.processKey(['.Get.CPC("{0}").Input(New TimeSpan())\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        case 'cpc-boolean': SIDAction.processKey(['.Get.CPC("{0}").Input()\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
            break;

        default: SIDAction.processKey(['.Get.CPC("{0}").Input("")\n', '.Get.CPC("{0}")', 'Driver'], SID, false);
    }
}

SIDAction.updateSeleniumInfo = function (name, key, value, addStyle) {

    var rootElement = document.getElementById('selenium-info');
    if (rootElement.getElementsByTagName(name).length == 0) { rootElement.appendChild(document.createElement(name)) }

    var nameElement = rootElement.getElementsByTagName(name)[0];
    if (nameElement.getElementsByTagName(key).length == 0) { nameElement.appendChild(document.createElement(key)) }

    var keyElement = nameElement.getElementsByTagName(key)[0];
    if (addStyle) keyElement.style.display = 'table-row';
    keyElement.innerText = value;

}