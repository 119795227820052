//Functions
//Grid
function onRowDropping(sender, args, senderId, destId1, destId2, recycleBinId1, recycleBinId2, alertText, canSort) {
    if (sender.get_id() == senderId) {
        var node = args.get_destinationHtmlElement();
        if (isChildOf(senderId, node) || isChildOf(destId1, node) || isChildOf(destId2, node)) {
        }
        else if (isChildOf(recycleBinId1, node)) {
            if (confirm(alertText)) { args.set_destinationHtmlElement($get(recycleBinId1)); }
            else { args.set_cancel(true); }
        }
        else if (isChildOf(recycleBinId2, node)) {
            if (confirm(alertText)) { args.set_destinationHtmlElement($get(recycleBinId2)); }
            else { args.set_cancel(true); }
        }
        else {
            args.set_cancel(true);
        }
    }
    else {
        args.set_cancel(true);
    }
}

//Misc
function isChildOf(parentId, element) {
    if (parentId == "") { return false; }
    while (element) {
        if (element.id && element.id.indexOf(parentId) > -1) {
            return true;
        }
        element = element.parentNode;
    }
    return false;
}