document.addEventListener("DOMContentLoaded", function () {
    documentReady();
});


function documentReady() {

    if (typeof jQuery === 'undefined') {
        console.log("jquery is missing");
        return;
    }
    //InIt
    setupHelp();
    editorsSetup();
    dateControlSetup();

    //Events
    $('.ViewImage').colorbox({ onComplete: function () { $.colorbox.resize(); $('#cboxClose').remove(); } });
    $("#CookieControlCentre,.CookieControlCentreFooter").colorbox({ iframe: true, scrolling: true, width: "80%", height: "80%", closeButton: false, overlayClose: false, escKey: false });
}

//Init Functions
var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;                       // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera) 
var isFirefox = typeof InstallTrigger !== 'undefined';                                           // Firefox 1.0+ 
var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;    // At least Safari 3+: "[object HTMLElementConstructor]" 
var isChrome = !!window.chrome && !isOpera;                                                      // Chrome 1+ 
var isIE = /*@cc_on!@*/false || document.documentMode;


