//ControlBaseEx
Element.prototype.active = function (state) {
    if (state) {
        $(this).show();
    }
    else {
        $(this).hide();
    }
    return this.validationActive(state);
};

Element.prototype.validationActive = function (state) {
    var i;
    for (i = 0; i < Page_Validators.length; i++) {
        var eId = this.id;
        var vId = Page_Validators[i].id;
        if (vId.startsWith(eId)) { ValidatorEnableNoValidate(Page_Validators[i], state); }
    }
    return this;
};

function ValidatorEnableNoValidate(val, enable) {
    val.enabled = (enable != false);
}

function ToggleRadRadioButtonEx(val) {
    var button = $find(val);
    button.set_checked(!button.get_checked());
}
